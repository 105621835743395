import { Link } from 'gatsby'
import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../utility/class-names'
import { HomeIcon } from '@heroicons/react/24/solid'

export interface BreadcrumbProps {
  className?: string
  crumbs: [title: string, to: string][]
}

export default ({ className, crumbs }: BreadcrumbProps) => {
  return (
    <nav aria-label="Breadcrumb" className={classNames(
      className,
      'flex'
    )}>
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to='/' className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {crumbs.map(([title, to]) =>
          <li key={to}>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              <Link
                to={to}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {title}
              </Link>
            </div>
          </li>
        )}
      </ol>
    </nav>
  )
}