import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { Offer } from '../../contracts/offer'

export interface OfferDetailsProps {
  offer: Offer
  image?: IGatsbyImageData
  availableThroughImage?: IGatsbyImageData
}

export default ({ offer, image, availableThroughImage }: OfferDetailsProps) => {
  return (
    <section className='outline outline-gray-200 bg-white rounded-lg p-6 grid grid-cols-1 lg:grid-cols-2 lg:py-12 lg:px-8'>
      <div className='text-center mt-2 mb-8 lg:mb-0 space-y-4'>
        {image && 
          <GatsbyImage
            image={image}
            alt={offer.offeredBy.name}
            objectFit='contain'
            className="max-w-[220px] mb-4 bg-white border-4 border-white"
          />
        }
        <h1 className='text-2xl lg:text-3xl text-gray-700 dark:text-gray-200'>
          <span className="text-primary-600 dark:text-primary-400 font-semibold">{offer.title} </span>
        </h1>

        {offer.description &&
          <>
            <h3 className='pt-4 text-left font-medium text-gray-900'>
              About this Offer
            </h3>
            <p className='text-left whitespace-pre-line'>{offer.description}</p>
          </>
        }
        
      </div>

      <div className='text-gray-700'>
        <div className="flex flex-col items-center sm:flex-row mb-4">
          <div className='flex flex-col items-start grow mb-8 sm:mb-0'>
            <h3 className='font-medium text-gray-900'>
              Available through
            </h3>
            <div className='flex flex-col items-center ml-4'>
              {availableThroughImage &&
                <GatsbyImage
                  image={availableThroughImage}
                  alt={offer.availableThrough.name}
                  objectFit='contain'
                  className="w-20 h-20"
                />
              }
              <p className='text-lg'>
                {offer.availableThrough.name}
              </p>
            </div>
          </div>

          {offer.link &&
            <div className=''>
              <Link className='mb-8 inline-block button button-link' to={offer.link} target="_blank">
                Offer Details
                <ChevronRightIcon className='w-6 mb-1 mr-0 ml-1 inline' />
              </Link>
            </div>
          }
        </div>

        {offer.terms &&
          <>
            <h3 className='mt-4 font-medium text-gray-900 mb-2'>Terms</h3>
            <p className='text-sm mb-8 whitespace-pre-line'>{offer.terms}</p>
          </>
        }
      </div>
    </section>
  )
}