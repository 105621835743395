import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { graphql, HeadProps, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import OfferCardGrid from '../../components/offer-card-grid/offer-card-grid'
import OfferDetails from '../../components/offer-details/offer-details'
import { Offer } from '../../contracts/offer'

export const Head = ({ data }: HeadProps<{ offer: Offer }>) => {
  return (
    <title>{data.offer.title} - Disco Deals</title>
  )
}

export default (props: any) => {
  const offer = props.data.offer as Offer
  const moreOffers = offer.offeredBy.offers.filter(otherOffer => otherOffer.id !== offer.id).slice(0, 8)
  const categoryOffers = offer.categoryOffers.filter(categoryOffer => categoryOffer.id !== offer.id).slice(0, 8)

  const image = getImage(offer.offeredBy.image)
  const availableThroughImage = getImage(offer.availableThrough.image)

  return (
    <main className='pt-4 container'>
      <Breadcrumbs className='mb-4' crumbs={[
        [offer.category, `/categories/${offer.category.toLowerCase().replace(/ /g, '-')}`]
      ]} />

      <div className='mb-8'>
        <OfferDetails offer={offer} image={image} availableThroughImage={availableThroughImage} />
      </div>

      {moreOffers.length > 0 &&
        <section>
          <Link to={`/companies/${offer.offeredBy.id.toLowerCase()}`}>
            <h3 className='section-title text-link'>More offers from {offer.offeredBy.name} <ChevronDoubleRightIcon className="w-4 inline" /></h3>
          </Link>
          <OfferCardGrid offers={moreOffers} />
        </section>
      }

      {categoryOffers.length > 0 && 
        <section>
          <Link to={`/categories/${offer.category.toLowerCase().replace(/ /g, '-')}`}>
            <h3 className='section-title text-link'>
              More {offer.category} offers <ChevronDoubleRightIcon className="w-4 inline" />
            </h3>
          </Link>
          <OfferCardGrid offers={categoryOffers} />
        </section>
      }
    </main>
  )
}

export const query = graphql`
query($id: String) {
  offer(id: { eq: $id }) {
    ...OfferDisplay
    offeredBy {
			id
      name
      offers {
				...OfferDisplay
      }
      availableOffers {
				...OfferDisplay
      }
    }
    availableThrough {
      id
      name
      image {
        childImageSharp {
          gatsbyImageData(width: 200)
        }
      }
    }
    categoryOffers {
      ...OfferDisplay
    }
  }
}
`